import React, { useState } from "react";
import { toTitleCase } from "utils/titleCase";
import { Tooltip } from "react-tooltip";
import * as XLSX from "xlsx"; 
import UndoIcon from "assets/undo_icon.svg"

const OutputHeader = ({ title, output, copyText, downloadData, tooltiptext,terms, isContext=false,undoVisible, 
  handleUndoDelete,saveNewTerm, success,
  editingIndex, 
  result,
  showSaveButton,
  handleSaveTranslations,
   updatedTranslations,
}) => {
  const [copy, setCopy] = useState("Copy");
  const copyAnalysis = () => {
    let textToCopy = `Overall Complexity: ${output?.overall_complexity}\n`;
    textToCopy += `Suggested Translation Type: ${toTitleCase(
      output?.suggested_translation_type
    )}\n\n`;

    if (output?.categories) {
      for (const [key, value] of Object.entries(output.categories)) {
        textToCopy += `Category: ${key
          .replace(/_/g, " ")
          .replace(/^\w/, (c) => c.toUpperCase())}\n`;
        textToCopy += `Score: ${value.score}\n`;
        textToCopy += `Explanation: ${value.explanation}\n\n`;
      }
    }


    navigator.clipboard.writeText(textToCopy).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const handleCopy = () => {
    if (copyText) {
      copyText();
    } else {
      copyAnalysis();
    }
    setCopy("Copied");
    setTimeout(() => {
      setCopy("Copy");
    }, 2000);
  };

  const downloadXlsx = () => {
    const data = [];

    if (output?.categories) {
      for (const [key, value] of Object.entries(output.categories)) {
        const row = {
          Category: key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase()),
          Score: value.score,
          Explanation: value.explanation,
        };
        data.push(row);
      }
    }

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Analysis");
    const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(excelFile)], { type: "application/octet-stream" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "text_complexity_assessment.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const handleDownload = () => {
    if (downloadData) {
      downloadData();
    } else {
      downloadXlsx();
    }
    setCopy("Copied");
    setTimeout(() => {
      setCopy("Copy");
    }, 2000);
  };
  const isAddingNewTerm = result?.some(item => item?.isNew);
  const shouldShowSaveButton = (isContext && editingIndex !== null && success !== 1 && isAddingNewTerm || updatedTranslations?.length > 0);
  // const shouldShowSaveTranslationsButton = updatedTranslations?.length > 0;
  return (
    <>
      <div className="flex justify-between w-full items-center">
        <div className="flex gap-2">
          <p className="text-[#2B2B2B] font-bold text-base font-opensans">
            {title}
          </p>
          {!terms && (
            <button
              data-tooltip-id="data-value-tooltip"
              data-tooltip-content={tooltiptext}
              className=""
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM7.2 10.4H8.8V12H7.2V10.4ZM8.8 9.084V9.6H7.2V8.4C7.2 8.18783 7.28429 7.98434 7.43431 7.83431C7.58434 7.68429 7.78783 7.6 8 7.6C8.22726 7.59999 8.44985 7.53544 8.64186 7.41386C8.83387 7.29229 8.98741 7.11869 9.08462 6.91327C9.18182 6.70784 9.2187 6.47904 9.19095 6.25347C9.1632 6.02791 9.07196 5.81487 8.92787 5.63913C8.78377 5.46339 8.59273 5.33218 8.37698 5.26078C8.16122 5.18937 7.92963 5.18069 7.70914 5.23576C7.48865 5.29083 7.28833 5.40738 7.13149 5.57185C6.97465 5.73632 6.86774 5.94195 6.8232 6.1648L5.2536 5.8504C5.35091 5.36407 5.57561 4.9123 5.90474 4.54127C6.23387 4.17024 6.65561 3.89327 7.12687 3.73866C7.59813 3.58405 8.10199 3.55735 8.58695 3.66129C9.0719 3.76524 9.52056 3.99609 9.88706 4.33026C10.2535 4.66443 10.5247 5.08992 10.6729 5.56325C10.821 6.03658 10.8409 6.54076 10.7303 7.02425C10.6197 7.50774 10.3828 7.9532 10.0436 8.31509C9.70447 8.67698 9.27531 8.94233 8.8 9.084Z"
                  fill="#B0B0B0"
                />
              </svg>
              <Tooltip
                id="data-value-tooltip"
                place="bottom-center"
                multiline={true}
                className="z-50 !w-[300px] sm:!w-[400px] !font-opensans font-normal !text-sm !text-start"
              />
            </button>
          )}
        </div>
      
        <div className="flex items-center gap-3">
        {isContext && undoVisible && (
          <div className="flex items-center gap-3">
            <button onClick={handleUndoDelete}>
              <img src={UndoIcon} alt="Undo Icon" />
            </button>
          </div>
        )}
             {/* {isContext && shouldShowSaveTranslationsButton && (
            <button
              onClick={handleSaveTranslations}
              className="bg-[#5B93FF] text-white p-2 rounded-md"
            >
              Save Translations
            </button>
          )} */}
             {isContext && shouldShowSaveButton && (
          <button
            onClick={() => saveNewTerm(editingIndex)} 
            className="bg-[#5B93FF] px-3 py-1 ml-3 text-white font-semibold rounded-md"
          >
            Save changes
          </button>
        )}
          <svg
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copy}
            onClick={handleCopy}
            className="cursor-pointer outline-none"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.28652 0C5.86218 0 5.45521 0.168571 5.15515 0.468629C4.85509 0.768687 4.68652 1.17565 4.68652 1.6V3.2H6.28652V1.6H15.0865V10.4H13.4865V12H15.0865C15.5109 12 15.9178 11.8314 16.2179 11.5314C16.518 11.2313 16.6865 10.8243 16.6865 10.4V1.6C16.6865 1.17565 16.518 0.768687 16.2179 0.468629C15.9178 0.168571 15.5109 0 15.0865 0H6.28652ZM2.28652 4C1.86218 4 1.45521 4.16857 1.15515 4.46863C0.855094 4.76869 0.686523 5.17565 0.686523 5.6V14.4C0.686523 14.8243 0.855094 15.2313 1.15515 15.5314C1.45521 15.8314 1.86218 16 2.28652 16H11.0865C11.5109 16 11.9178 15.8314 12.2179 15.5314C12.518 15.2313 12.6865 14.8243 12.6865 14.4V5.6C12.6865 5.17565 12.518 4.76869 12.2179 4.46863C11.9178 4.16857 11.5109 4 11.0865 4H2.28652ZM2.28652 5.6H11.0865V14.4H2.28652V5.6Z"
              fill="#5B93FF"
            />
          </svg>
          <svg
            data-tooltip-id="download-tooltip"
            data-tooltip-content="Download"
            onClick={handleDownload}
            className="cursor-pointer outline-none"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
          >
            <path
              d="M2.72574 14.8571C2.18491 14.8571 1.66622 14.6422 1.2838 14.2598C0.901369 13.8774 0.686523 13.3587 0.686523 12.8179V9.90471C0.686523 9.67293 0.7786 9.45063 0.942497 9.28674C1.10639 9.12284 1.32869 9.03076 1.56047 9.03076C1.79226 9.03076 2.01455 9.12284 2.17845 9.28674C2.34235 9.45063 2.43442 9.67293 2.43442 9.90471V12.8179C2.43442 12.9787 2.56493 13.1092 2.72574 13.1092H14.961C15.0383 13.1092 15.1124 13.0785 15.167 13.0239C15.2217 12.9692 15.2523 12.8951 15.2523 12.8179V9.90471C15.2523 9.67293 15.3444 9.45063 15.5083 9.28674C15.6722 9.12284 15.8945 9.03076 16.1263 9.03076C16.3581 9.03076 16.5804 9.12284 16.7443 9.28674C16.9082 9.45063 17.0002 9.67293 17.0002 9.90471V12.8179C17.0002 13.3587 16.7854 13.8774 16.403 14.2598C16.0205 14.6422 15.5019 14.8571 14.961 14.8571H2.72574Z"
              fill="#5B93FF"
            />
            <path
              d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
              fill="#5B93FF"
            />
          </svg>
        </div>
      </div>
      <Tooltip
        id="copy-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
      <Tooltip
        id="download-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
      <hr className="my-4 border border-[#C7C7C7]" />
    </>
  );
};

export default OutputHeader;

